import React from "react";

import { buttonClose, closing as closingClass } from "./menu-close-button.module.scss";
import CloseSVG from "../../assets/images/svg/letter-x.svg";

import Button from "./button";

interface IMenuCloseButton {
    className?: string;
    closing?: boolean;
    onClick?: () => void;
}

export default function MenuCloseButton({ className, closing, onClick }: IMenuCloseButton) {
    return (
        <Button
            color="white"
            onClick={onClick}
            className={`${buttonClose} ${closing ? closingClass : ""} ${className}`}
        >
            
            <CloseSVG />
        </Button>
    );
}
