import React, { useEffect, useState } from "react";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";
import { useLocation } from "@reach/router";

import { mainGrid } from "../styles/grid.module.scss";
import {
    layout,
    survey,
    overflow as overflowClass,
    overflowY,
    paddingTop,
    cookiesModule,
    main,
} from "./main-layout.module.scss";
import { MainContext } from "./main-layout.context";
import { cookies, cookiesConfig as defaultCookiesConfig } from "../config/cookies";
import useWindowScroll from "../hooks/useWindowScroll";
import useCookiesTranslations from "../hooks/cookies/use-cookies-translations";
import { useModalContext } from "../contexts/modal.context";

import Footer from "../components/organisms/footer";
import Header from "../components/organisms/headers/header";
import Modal from "../components/modals/modal";
import Logo from "../components/atoms/logo";
import SEO, { ISEOProps } from "../components/seo";
import CookiesBox from "../components/organisms/cookies-box";

export interface IMainLayoutProps {
    children?: any;
    className?: string;
    containerClassName?: string;
    headerClassName?: string;
    headerColor?: "white" | "black";
    footer?: boolean;
    overflow?: boolean;
    hasPaddingTop?: boolean;
    isSurvey?: boolean;
    HeaderComponent?: React.ReactElement | null;
    popupComponent?: React.ReactElement;
    showPopup?: boolean;
    canClosePopup?: boolean;
    SEOProps?: ISEOProps;
}

const MainLayout: React.FC<IMainLayoutProps> = ({
    children,
    className = "",
    containerClassName = "",
    headerClassName = "",
    headerColor = "black",
    footer = true,
    overflow = true,
    hasPaddingTop = false,
    isSurvey = false,
    HeaderComponent = <Header color="white" />,
    popupComponent,
    showPopup = false,
    canClosePopup = true,
    SEOProps,
}) => {
    const [showModal, setShowModal] = useState(showPopup);
    const [verticalOverflow, setVerticalOverflow] = useState(false);
    const { y } = useWindowScroll();
    const location = useLocation();
    const cookiesConfig = useCookiesTranslations(defaultCookiesConfig);
    const { modals } = useModalContext();

    const handleCookiesSaved = () => {
        initializeAndTrack(location);
    };

    useEffect(() => {
        if (typeof showModal === "boolean") {
            setVerticalOverflow(showModal);
        }
    }, [showModal]);

    useEffect(() => {
        setShowModal(showPopup);
    }, [showPopup]);

    return (
        <MainContext.Provider
            value={{
                showModal,
                setShowModal,
            }}
        >
            <SEO
                {...SEOProps}
                className={`${SEOProps?.className || ""} ${verticalOverflow ? overflowY : ""}`}
            />
            <div
                className={`\
                    ${layout}\
                    ${overflow ? overflowClass : ""}\
                    ${hasPaddingTop ? paddingTop : ""}\
                    ${containerClassName}\
                `}
            >
               

                {HeaderComponent &&
                    React.cloneElement(HeaderComponent, {
                        color: headerColor,
                        scrolled: y >= 50,
                        className: headerClassName,
                    })}

                <main className={`${mainGrid} ${main} ${isSurvey ? survey : ""} ${className}`}>
                    {children}
                </main>

                <CookiesBox isVisible={true}></CookiesBox>

                <Footer isVisible={footer} />

                {modals.map(modal => {
                    return (
                        <Modal modal={modal} key={modal.modalId} />
                    )
                })}
            </div>
        </MainContext.Provider>
    );
};

export default MainLayout;
