// extracted by mini-css-extract-plugin
export var bottomLogo = "menu-vertical-module--bottom-logo--YOaPn";
export var fadeIn = "menu-vertical-module--fade-in--q5u5s";
export var fadeOut = "menu-vertical-module--fade-out--6mgqb";
export var iconSpecial = "menu-vertical-module--icon-special--i4ErE";
export var navItem = "menu-vertical-module--nav-item--MbJED";
export var navItemOutlined = "menu-vertical-module--nav-item-outlined--rM4jt";
export var slideFromBottom = "menu-vertical-module--slide-from-bottom--Gnunu";
export var slideFromLeft = "menu-vertical-module--slide-from-left--VPgO+";
export var slideFromRight = "menu-vertical-module--slide-from-right--gvHqi";
export var slideFromTop = "menu-vertical-module--slide-from-top--pX8v4";
export var slideToBottom = "menu-vertical-module--slide-to-bottom--zzcXy";
export var slideToLeft = "menu-vertical-module--slide-to-left--FhU25";
export var slideToRight = "menu-vertical-module--slide-to-right--vrP8m";
export var slideToTop = "menu-vertical-module--slide-to-top--+wUV9";
export var zoomIn = "menu-vertical-module--zoom-in--1trj0";
export var zoomOut = "menu-vertical-module--zoom-out--9h07Y";