const API_URL = process.env.API_URL;
const API_NEW_URL = process.env.API_NEW_URL;

module.exports = {
    authors: {
        all: (perPage = 1024) =>
            `${API_URL}/authors?per-page=${perPage}&expand=translations,media,posts`,
    },
    sliderProducts: {
        all: (perPage = 1024) => `${API_URL}/products/slider?per-page=${perPage}`,
    },
    contact: `${API_URL}/contact`,
    bannersCollections: {
        all: (perPage = 512) =>
            `${API_URL}/banner-collections?per-page=${perPage}&expand=translations,media,banners.media,banners.translations,banners.translations.media`,
    },
    posts: {
        all: (perPage = 512) =>
            `${API_URL}/posts?per-page=${perPage}&expand=translations,media,author,relatedPosts,tags,categories`,
    },
    faq: {
        all: (perPage = 1024) => `${API_URL}/faqs?per-page=${perPage}`,
    },
    tags: (perPage = 1024) => `${API_URL}/tags?per-page=${perPage}&expand=posts`,
    stylizations: {
        page: `${API_URL}/stylizations`,
        single: (stylizationId, guid) => `${API_NEW_URL}/stylization/${stylizationId}${guid ? '/' + guid : ''}`,
        update: (stylizationId) => `${API_URL}/stylizations/${stylizationId}`,
        delete: (stylizationId) => `${API_URL}/stylizations/${stylizationId}`,
        create: `${API_NEW_URL}/stylization`,
        filters: `${API_URL}/stylizations/filters`,
        products: `${API_NEW_URL}/stylization/products`,
    },
    surveys: {
        entity: `/surveys`,
        sessions: `/sessions`,
        questions: `/questions`,
        pages: `/pages`,
    },
    postCategories: {
        all: (perPage = 1024) => `${API_URL}/post-categories?per-page=${perPage}&expand=postsIds`,
    },
};
