import React, { ReactElement } from "react";

import { bigLogo, sygnet } from "./logo.module.scss";
import LogoSvg from "../../assets/images/svg/Fashion-fairy-logo.svg";
import Sygnet from "../../assets/images/svg/Fashion-fairy-sygnet.svg";
import generateLogoClassName from "../../utils/generate-logo-classname";

interface ILogoPropsBase {
    color: "white" | "black";
    type: "logo" | "sygnet" | "responsive";
    className?: string;
}

type LogoMap = Omit<Record<ILogoPropsBase["type"], ReactElement>, "responsive">;

export type ILogoProps = Omit<ILogoPropsBase, "type"> & Partial<Pick<ILogoPropsBase, "type">>;

const logoMap: LogoMap = {
    logo: <LogoSvg className={bigLogo} />,
    //logo: <Sygnet className={bigLogo} />,
    sygnet: <Sygnet className={sygnet} />,
};

export default function Logo2({ color, type = "responsive", className = "" }: ILogoProps) {
    const responsiveLogos = (
        <>
            {logoMap["logo"]}
            {logoMap["sygnet"]}
        </>
    );

    const logoClassName = generateLogoClassName({ type, color, className });
    return (
        <div className={logoClassName}>
            {type === "responsive" ? responsiveLogos : logoMap[type]}
        </div>
    );
}
