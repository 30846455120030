/*
 * If you don't pass permissions property, the menu link will be visible always, independently of permissions
 */

module.exports = [
    {
        labelKey: "nav.about",
        to: "/o-nas",
        area: "center",
        type: "nav-item",
    },
    {
        labelKey: "nav.blog",
        to: "/blog",
        area: "center",
        type: "nav-item",
    },
    {
        labelKey: "nav.contact",
        to: "/kontakt",
        area: "center",
        type: "nav-item",
    },
    {
        labelKey: "nav.logo",
        to: "/",
        logo: true,
        area: "left",
    },
    {
        labelKey: "nav.login",
        to: "/auth",
        area: "center",
        type: "nav-item",
        permissions: "guest",
    },
    {
        labelKey: "nav.stylize",
        to: "/wystylizuj-mnie",
        type: "filled",
        area: "right",
        permissions: "guest",
    },
    {
        labelKey: "nav.your-account",
        to: "/konto",
        type: "nav-item",
        area: "center",
        permissions: "User",
    },
];
